(function () {

    'use strict';

    // TODO: Re-order alphabetically.

    angular
        .module('app')
        .constant('angularMomentConfig', {
            timezone: 'America/Chicago'
        })
        .constant('buildNumber', 'CI_master_20241003.1')
        .constant('dotNetMaximumInteger', 2147483647)
        .constant('apiUrlPrefix', 'https://fs-api.sbtestthis.com')
        .constant('apiUrlPort', '443')
        .constant('corsWithCredential', false)
        .constant('logDebugMessagesToConsole', false)
        .constant('filterDelayModelOptions', { debounce: 2000 })
        .constant('growlPosition', 'top-center')
        .constant('growlTimeToLive', { success: 5000, warning: 10000, info: 5000 })
        .constant('idleInSeconds', 29*60)
        .constant('idleTimeoutInSeconds', 60)
        .constant('invalidFileExtensions', ['asp', '7z', 'bzip', 'bzip2', 'com', 'dll', 'exe', 'gzip', 'htm', 'html', 'js', 'lnk', 'msi', 'ps1', 'tar', 'wim', 'xz', 'zip'])
        .constant('reCaptchaPublicKey', '6LcnJGAUAAAAAKH6ZJVvKOty2uqd9ZzlFH-zk5Lx')
        .constant('userType', { administrator: 1, staff: 2, client: 3 })
        .constant('helpUrl', 'https://fs-web.sbtestthis.com/help/FileShare.htm')
        .constant('lazyListNumberOfItemsPerLoad', 25)
        .constant('maximumUploadSize', 2147482624)  // 2GB - 1KB
        .constant('minimumFileDisplayPercent', 80)
        .constant('maximumEmailBodySize', 10000)
        .constant('maximumNumberOfFilesPerAction', 100)
        .constant('maximumLogoFileSize', '3MB')
        .constant('maximumLogoDimension', 120)
        .constant('maximumLogoHeight', 120)
        .constant('maximumLogoWidth', 500)
        .constant('defaultLogoWidth', 150)
        .constant('ieMinimumVersion', 11)

        // RegEx expressions for routes that do not require authentication
        .constant('unauthenticatedRoutes', [
            /^\/\d+\/login$/i,
            /^\/\d+\/register$/i,
            /^\/\d+\/requestPasswordReset$/i,
            /^\/\d+\/resetPassword\//i
        ])

        // Default toolbar configuration for summernote
        .constant('summernoteToolbarOptions', [
            ['headline', ['style']],
            ['style', ['clear', 'bold', 'underline', 'strikethrough']],
            ['fontface', ['fontname']],
            ['textsize', ['fontsize']],
            ['fontclr', ['color']],
            ['alignment', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link']],
            ['view', ['codeview']],
            ['email', ['keywords']]
        ])

        .constant('summernoteFontNamesIgnoreCheck', ['Fira Sans'])

        .constant('summernoteFontNames', [
            'Ariel', 'Ariel Black', 'Calibri', 'Comic Sans MS', 'Courier New', 'Fira Sans',
            'Helvetica', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'
        ])

        // Make sure that the below value (tokenExpiresInMinutes) is the same as TokenSpanInMinutes in Api web.config
        .constant('tokenExpiresInMinutes', 30)
        .constant('userSessionExpiresInMinutes', 24 * 60)
        .constant('timeframeToCheckRefreshTokenBeforeFOrceLogout', 30)
        .constant('intervalToCallForceLogoutIfIdleInSeconds', 30)
        .constant('intervalToCallForceLogoutIfMaximumSessionSpanExceededInSeconds', 30)

        // Valid GUID pattern with or without (optional) hyphens
        .constant('validGuid', /^[0-9a-f]{8}(?:-)*[0-9a-f]{4}(?:-)*[0-9a-f]{4}(?:-)*[0-9a-f]{4}(?:-)*[0-9a-f]{12}$/i);
})();
